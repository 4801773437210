import cn from 'classnames';

import type {ImageComponent} from '@/types';
import {ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import Section from '@/components/brochureV2/primitive/Section/Section';
import Image from '@/components/shared/Image/Image';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Tooltip, {
  type TooltipProps,
} from '@/components/pos/primitive/Tooltip/Tooltip';
import Bauble from '@/components/pos/primitive/Bauble/Bauble';
import {twMerge} from '@/stylesheets/twMerge';

interface AnnotationProps {
  id: string;
  heading?: string;
  content: string;
  positionClasses?: string;
}

interface AnnotatedImageProps {
  colorScheme?: ColorSchemeEnum;
  showTopSpacer?: boolean;
  annotations?: AnnotationProps[];
  desktopImage?: ImageComponent;
  mobileImage?: ImageComponent;
  className?: string;
}

export default function AnnotatedImage({
  colorScheme,
  showTopSpacer = true,
  desktopImage,
  mobileImage,
  annotations,
  className,
  ...props
}: AnnotatedImageProps) {
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  const hasTooltips = annotations?.some((annotation) => annotation.heading);

  return (
    <Section
      {...props}
      className={twMerge(
        cn({
          'overflow-hidden': hasTooltips,
        }),
        sectionSchemeClasses[currentScheme],
        className,
      )}
    >
      {showTopSpacer && <Spacer size="3xl" />}
      <div className="container relative">
        {desktopImage && (
          <Image
            className="hidden sm:block w-full max-w-full"
            alt={desktopImage.alt}
            sizes={desktopImage.sizes}
            srcSet={desktopImage.srcSet}
            loading="lazy"
          />
        )}
        {mobileImage && (
          <Image
            className="sm:hidden w-full max-w-full mx-auto"
            alt={mobileImage.alt}
            sizes={mobileImage.sizes}
            srcSet={mobileImage.srcSet}
            loading="lazy"
          />
        )}
        {annotations?.map((annotation) => {
          if (annotation.heading) {
            return (
              <Tooltip {...(annotation as TooltipProps)} key={annotation.id} />
            );
          }

          return <Bauble {...annotation} key={annotation.id} />;
        })}
      </div>
      <Spacer size="3xl" />
    </Section>
  );
}
