import {twMerge} from '@/stylesheets/twMerge';

interface BaubleProps {
  positionClasses?: string;
  content: string;
}

const Bauble = ({content, positionClasses}: BaubleProps) => {
  let containerClasses = 'absolute bg-lime-10 p-3 rounded-3xl shadow-sm';
  containerClasses = twMerge(containerClasses, positionClasses);

  return (
    <div className={containerClasses} aria-hidden="true">
      <p className="font-bold text-lg leading-tight whitespace-pre text-shade-90">
        {content}
      </p>
    </div>
  );
};

export default Bauble;
