import {formatCurrency} from '@/utils/string/formatCurrency';
import type {Site} from '@data/types';
import type {Plan} from '@/components/shared/Plans/FeaturesTable/utils/normalizePlanTypes';

type ServerPlansData = {
  mainPlans: Plan[];
  alternatePlans: Plan[];
};

export const retailSubscriptionIncentivePrice = (
  site: Site,
  serverPlansData: ServerPlansData,
): string => {
  const posProIncentivePrice = serverPlansData?.mainPlans?.find(
    (plan: Plan) => plan.monthly_pos_pro_retail_subscription_incentive_price,
  )?.monthly_pos_pro_retail_subscription_incentive_price;

  return formatCurrency(
    site.locale,
    posProIncentivePrice?.currency_code,
    posProIncentivePrice?.amount,
    {
      maximumFractionDigits: 0,
    },
  );
};
