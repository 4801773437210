import cn from 'classnames';
import {useState, useRef, useEffect} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
export interface TooltipProps {
  id: string;
  heading: string;
  content: string;
  positionClasses?: string;
  isExpandable?: boolean;
}

const Tooltip = ({
  id,
  heading,
  content,
  positionClasses,
  isExpandable = true,
}: TooltipProps) => {
  const [isActive, setIsActive] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const expandedRef = useRef<HTMLDivElement>(null);

  let containerClasses = cn('absolute bg-lime-10 p-3 rounded-3xl shadow-sm', {
    'left-1/2 -translate-x-1/2 w-11/12 sm:left-auto sm:translate-x-0 sm:w-[264px] pl-4 z-10':
      isActive,
    'w-12 h-12 sm:pl-4 sm:w-[264px] sm:h-auto ': !isActive,
  });
  containerClasses = twMerge(containerClasses, positionClasses);

  useEffect(() => {
    const measureContent = expandedRef.current
      ? expandedRef.current.getBoundingClientRect().height
      : 0;
    if (isActive && measureContent) setContentHeight(measureContent);
    else setContentHeight(0);
  }, [isActive]);

  return isExpandable ? (
    <div className={containerClasses} id={id} role="tooltip" title={heading}>
      <button
        type="button"
        aria-expanded={isActive}
        aria-controls={`${id}-text`}
        id={`${id}-btn`}
        className="flex w-full cursor-pointer items-center justify-between gap-2 font-bold text-lg leading-tight group"
        data-component-name={`${`${id}-btn`}-${isActive ? 'hide' : 'show'}`}
        onClick={() => setIsActive(!isActive)}
        aria-label={heading}
      >
        <h3
          className={cn('text-left w-[200px]', {
            'invisible absolute sm:visible sm:static': !isActive,
          })}
        >
          {heading}
        </h3>
        <div
          aria-hidden={!isActive}
          className={cn(
            'relative w-[24px] h-[24px] block rounded-full text-shade-90 border-shade-90 border-2',
            'before:content-[""] before:block before:w-[2px] before:bg-shade-90 before:absolute before:left-2/4 before:-translate-x-2/4 before:top-2/4 before:-translate-y-2/4 before:duration-300 before:transition-all',
            'after:content-[""] after:block after:w-[14px] after:h-[2px] after:bg-shade-90 after:absolute after:left-2/4 after:-translate-x-2/4 after:top-2/4 after:-translate-y-2/4',
            {
              'before:h-[2px]': isActive,
              'before:h-[14px]': !isActive,
            },
          )}
        />
      </button>
      <div
        ref={wrapperRef}
        aria-hidden={!isActive}
        className="text-sm overflow-hidden transition-all"
        style={{maxHeight: `${contentHeight}px`}}
        id={`${id}-text`}
        role="region"
        aria-labelledby={`${id}-btn`}
      >
        <div
          ref={expandedRef}
          className={cn('transition-opacity pt-3 pr-4', {
            'opacity-0': !isActive,
            'opacity-100': isActive,
          })}
        >
          {content}
        </div>
      </div>
    </div>
  ) : (
    <div className={containerClasses} id={id} role="tooltip">
      <h3 className="flex w-full items-center justify-between gap-2 font-bold text-lg leading-tight group">
        {heading}
      </h3>
      <p className="pt-3 pr-4">{content}</p>
    </div>
  );
};

export default Tooltip;
